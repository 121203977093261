//
// Main Style
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap framework includes
@import "bootstrap/scss/bootstrap";

// 3: Components
@import
    "components/utilities/background",
    "components/utilities/flex",
    "components/utilities/opacity",
    "components/utilities/borders",
    "components/utilities/sizing",
    "components/utilities/position",
    "components/utilities/text",
    "components/utilities/icon",
    "components/burger-icon",
    "components/card",
    "components/wizard",
    "components/type",
    "components/breadcrumb",
    "components/nav",
    "components/alert",
    "components/buttons",
    "components/code",
    "components/dropdown",
    "components/forms",
    "components/grid",
    "components/modal",
    "components/progress",
    "components/table",
    "components/popover",
    "components/tooltip",
    "components/accordion",
    "components/datatable",
    "components/bar-chart",
    "components/checkbox",
    "components/radio",
    "components/switch",
    "components/option",
    "components/dialog",
    "components/strikethrough",
    "components/separator",
    "components/image-input",
    "components/label",
    "components/loader",
    "components/symbol",
    "components/pulse",
    "components/page-loader",
    "components/quick-search",
    "components/scrolltop",
    "components/spinner",
    "components/sticky-toolbar",
    "components/svg-icon",
    "components/svg",
    "components/timeline",
    "components/timeline-2",
    "components/timeline-3",
    "components/timeline-4",
    "components/timeline-5",
    "components/overlay",
    "components/demo",
    "components/wave",
    "components/list",
    "components/bullet",
    "components/navi",
    "components/ribbon",
    "components/toggle";

// 4: Plugins
@import
    "vendors/plugins/formvalidation",
    "vendors/plugins/dropzone",
    "vendors/plugins/perfect-scrollbar",
    "vendors/plugins/prismjs",
    "vendors/plugins/apexcharts";

// 5: Layout
@import "layout/init";

// 6: Bs DatePicker
@import "vendors/bs-datepicker/bs-datepicker";

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

//Um monte de estilo para o ng-select ficar igual ao nosso estilo
.ng-select, .ng-select .ng-select-container {
    min-height: calc(1.5em + 0.3rem + 2px) !important;
  }
.ng-select.ng-select-single .ng-select-container,
.custom-file, .custom-file-input, .custom-file-label {height: calc(1.5em + 0.9rem + 2px) !important;}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow { top: -0.14rem !important; }
.ng-select.ng-select-disabled > .ng-select-container { background-color: #f2f2f2; color: #a3a5a8 !important; }
.ng-select .ng-select-container .ng-value-container .ng-input>input { line-height: 1px; }
.ng-select .ng-select-container .ng-value-container .ng-placeholder { color: #5c6873!important; }
.ng-select .ng-clear-wrapper .ng-clear { line-height: 0.8!important; }
.ng-select.ng-select-opened > .ng-select-container {border-color: #e4e7ea #e4e7ea #e4e7ea;}
.ng-select .ng-select-container .form-control {
    border: 1px solid $gray-600 !important;

    &:focus,
    &.focus {
      border: 1px solid $digibyte;
    }
}
.ng-select-container.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: 1px solid $digibyte;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 0 3px #3699ff;
}

//Colocando o ng-select corretamente dentro de input-group
.input-group {
  .ng-select {
    flex: 1 1 auto;
    width: 1%;
  }
}

//Deixando os ícones menores nos inputs-group
.input-group i {
  font-size: 0.9rem !important;
}

//Tirando a margem de baixo de uma label, para ficar grudada com os inputs
label {
    margin-bottom: 0rem !important;
}

//Diminuindo o tamanho da thead das tables
.table thead th {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

//Colocando linhas nas tabelas
.table tbody tr {
  border-bottom: 1px solid #AEAEB5 !important;
  background-color: #fefefe !important;

  &:hover {
    background-color: #E1F0FF !important;
  }
}

//Colocando borda preta nos botões quando em foco
.btn {
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.1rem black !important;
    color: white !important;
  }
}

.form-control {
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.1rem $primary !important;
  }
}

.ng-select {
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.1rem $primary !important;
  }
}

//label
.custom-file-label::after {
    height: calc(1.5em + 0.9rem) !important;
}

//Utilizado para aparecer a descrição completa com três pontinhos no final
.db-truncate-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    max-width: 90%;
 }

//Mudar o cursor para a mãozinha dando a impressão de clicavel
.db-clicavel {
  cursor: pointer;
}

//Para deixar a fonte em negrito
.db-negrito {
  font-weight: bold;
}

//Mexendo com o estilo da conta no relatório em tela do razão
.db-conta-razao {
  background-color: #DEE0E4 !important;
  &:hover {
      background-color: #C2C4CC !important;
  }
}

//Barra de rolagem nas mensagens de erro da importação do sped
.db-slide-bar-resultado-sped {
  overflow: auto;
  max-height: 400px;
}

//Colocando uma tr de uma table inteira como link clicável
.db-tr-link {
  cursor: pointer;
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

  @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
  }
}

//Colocando o estilo do link só quando passa o mouse em cima
.db-hover-link {
  cursor: pointer;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

//Mexendo com o estilo do Dropzone JS
.db-dropzone {
  height: 50px !important;
  > ngx-dropzone-preview {
      height: 30px !important;
      min-height: 30px !important;
      min-width: 100px !important;
      max-width: 266px !important;
      margin: 8px !important;
      > ngx-dropzone-label {
          font-size: 24px !important;
      }
  }
}

//Quando passar o mouse em cima do botão excluir item lançamento
.db-delete-item:hover {
    > .fa {
      color: crimson;
    }
}

//Tirando os padding dos botões no input group
.db-botao-input-group {
  padding: 0.1rem !important;
}

//Para o cursor ficar com a mãozinha que agarra
.db-pegavel {
  cursor: grab;
}

//Impossibilitando de mexer no tamanho do text area
.db-resize-none {
  resize: none;
}

//Colocando scroll no eixo y da table com height fixo em 65vh (view height)
.db-table-fixed {
  width: 100%;

  tbody {
    height: 65vh;
    overflow-y: auto;
    width: 100%;
  }

  thead, tbody, tr, th {
    display: block;
  }
}

//Colocando scroll no eixo y da table com height fixo em 52vh (view height)
.db-table-lancamentos-folha {
  table { display : table; height: 60vh; }
  tr { display : table-row; }
  thead { display : table-header-group; }
  tbody { display : table-row-group; max-block-size: 52vh; overflow-y: auto;}
  tfoot { display : table-footer-group; }
  col { display : table-column; }
  colgroup { display : table-column-group; }
  td, th { display :  table-cell; }
}

//Colocando scroll no eixo y da table com height fixo em 53vh (view height)
.db-table-calculos-folha {
  table { display : table; height: 60vh; }
  tr { display : table-row; }
  thead { display : table-header-group; }
  tbody { display : table-row-group; max-block-size: 52vh; overflow-y: auto;}
  tfoot { display : table-footer-group; }
  col { display : table-column; }
  colgroup { display : table-column-group; }
  td, th { display :  table-cell; }
}

.text-primary {
  td { color:  blue; }
}

//Tela geral com todas as tabelas, de funcionários e cálculos
.card-container-fixed {
  height: 85vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

//Tela onde vai a tabela dos cálculos
.card-calculo-folha {
  height: 80vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

//Tela onde vai a tabela dos funcionários
.card-height-fixed {
  height: 80vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

//Tela com título, nome do funcionário e período de cálculo
.card-height-top-fixed {
  height: 12vh;
}

//Card onde vão as tabelas de cálculos e lançamentos
.card-table-calculos {
  height: 62vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

//Diferenciando larguras das colunas em tabelas
.col-p { min-width: 50px; }
.col-m { min-width: 100px; }
.col-g { min-width: 150px; }
.col-gg { min-width: 200px; }
.col-eg { min-width: 300px; }
.col-egg { min-width: 500px; }

//Título dos calculos de folha
.db-rubricas-calculo-folha-titulo {
  font-weight: bold;
  td {
    border-bottom-color: black;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
}

//Lista de calculos de folha
.db-rubricas-calculo-folha {
  td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
}

//Para quando quisermos aplicar colspan no DataTable (copiado do calculo-folha.component.scss por Paulo Santos)
th.db-vazio {
  width: 0%; padding: 0; display: none;
}

td.db-vazio {
  display: none;
}

//Para fazer uma tag do HTML girar
.db-girar {
  animation: spin-animation 2s infinite;
  animation-timing-function: linear;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

//Borda do menu escolhido
.db-menu-ativo {
  border-color: $primary !important;
  border-width: medium !important;
}

//Icone que aparece ao lado do nome do menu
.db-menu-section-icon {
  align-self: center;
  flex: 0 0 35px;
}

// Título dos cards
.titulo {
  font-size: large;
}

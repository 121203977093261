/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "~highlight.js/styles/googlecode.css";
//@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles
@import "./assets/sass/style.angular.scss";
//@import "./assets/sass/vendors/angular/material/angular-material";
@import "./assets/sass/vendors/angular/material/include";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// PrimeNG Styles
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

.fixTableHead {
  overflow-y: auto;
  height: 400px;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

// th, td {
//   padding: 8px 15px;
// }


// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

// Ng-Select
@import "~@ng-select/ng-select/themes/default.theme.css";
//@import "~@ng-select/ng-select/scss/default.theme.scss";

//hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}

.mat-mdc-table-wrapper-dashboard .mat-table {
	min-width: 600px !important;
	width: 100% !important;
}

.position-static {
	position: static;
}

.dropdown-fluid {
  height: 100%;
  .topbar-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.mat-mdc-dialog-container {
  padding: 0 !important;
}

.mat-mdc-progress-spinner { --mdc-circular-progress-active-indicator-color: #187de4; }

// estilização da barra de rolagem do sistema
body::-webkit-scrollbar {
  width: 16px;
}

body::-webkit-scrollbar-track {
  background: #1a1a27;
}

body::-webkit-scrollbar-thumb {
  background-color: #B5B5C3;
  border-radius: 10px;
  border: 2px solid #1a1a27;
}

body,
body .p-component
{
//  font-family: "Open Sans", sans-serif; MENU
// v15 PRIME-NG
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

// estilização dos botões de escolhas nas tables
#opcoes {
  border: 1px solid lightgray;
  border-radius: 10px;
  width: 130px;
  background: #DDEBFA;
  margin-right: 145px;
}

.goto {
  min-width: 120px;
  width: 94%;
  height: 29px;
  margin: 2px;
}

.opcoesHover {
  width: 20px !important;
  height: 20px !important;

  #opcoes {
    display: none !important;
  }

  #icon {
    display: block !important;
  }
}

.opcoesHover:hover {
  #opcoes {
    display: block !important;
  }

  #icon {
    display: none !important;
  }
}

::ng-deep {
  ngb-tabset > .nav-tabs {
    display: none;
  }
}

.p-toast-detail {
  white-space: pre-line;
}
.item-menu-botao {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

// tabelas primeng
.p-datatable .p-datatable-header {
  background: rgb(230, 230, 240);
}

.p-datatable .p-datatable-thead > tr > th {
  background: rgb(234, 234, 240);
}

.selectable .p-checkbox .p-checkbox-box {
  border: 2px solid #909498;
}


/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

.sticky-toolbar {
    width: 46px !important;
    position: fixed !important;
    top: 30% !important;
    right: -30px !important;
    list-style: none !important;
    margin: 0 !important;
    z-index: 50  !important;
    background: #CCC !important;
    box-shadow: $dropdown-box-shadow !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;

    @include border-top-left-radius($border-radius);
    @include border-bottom-left-radius($border-radius);

    #Afastamento, #Desligamento, #Temporario,
    #Aprendiz, #Transferencia, #Estatutario,
    #Treinamento, #Vinculo, #MudancaCPF,
    #Estagio, #DirigenteSindical, #TrabalhoCedido,
    #MandatoEletivo, #Intermitente {
        display: none;
    }
}

.sticky-toolbar-active {
  width: 46px !important;
  position: fixed !important;
  top: 30% !important;
  right: 0px !important;
  list-style: none !important;
  margin: 0 !important;
  z-index: 50  !important;
  background: #CCC !important;
  box-shadow: $dropdown-box-shadow !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;

  @include border-top-left-radius($border-radius);
  @include border-bottom-left-radius($border-radius);

  #Afastamento, #Desligamento, #Temporario,
  #Aprendiz, #Transferencia, #Estatutario,
  #Treinamento, #Vinculo, #MudancaCPF,
  #Estagio, #DirigenteSindical, #TrabalhoCedido,
  #MandatoEletivo, #Intermitente {
      display: none;
  }
}

.divtooltip {
    position: relative;
    right: 55px;
    z-index: 51;
    border: 1px solid lightgray;
    background: #fff;
    padding: 4px;
    text-align: right;
}

.sticky-toolbar:hover {
    right: 0 !important;
}

.btn-icon:hover {
    #Afastamento, #Desligamento, #Temporario,
    #Aprendiz, #Transferencia, #Estatutario,
    #Treinamento, #Vinculo, #MudancaCPF,
    #Estagio, #DirigenteSindical, #TrabalhoCedido,
    #MandatoEletivo, #Intermitente {
        display: block;
    }
}

.intermitente-flashing {
    animation-name: intermitente;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes intermitente {
    0% {
        background: #5CB85C;
    }
    50% {
        background: #CCC;
    }
    100% {
        background: #5CB85C;
    }
}

.estatutario-flashing {
    animation-name: estatutario;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes estatutario {
    0% {
        background: #B22929;
    }
    50% {
        background: #CCC;
    }
    100% {
        background: #B22929;
    }
}

.temporario-flashing {
    animation-name: temporario;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes temporario {
    0% {
        background: #EA7D35;
    }
    50% {
        background: #CCC;
    }
    100% {
        background: #EA7D35;
    }
}

.estagio-flashing {
    animation-name: estagio;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes estagio {
    0% {
        background: #5CB85C;
    }
    50% {
        background: #CCC;
    }
    100% {
        background: #5CB85C;
    }
}

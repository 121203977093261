@include media-breakpoint-up(lg) {
	.nome-empresa {
                font-weight: bold;
                height: 100%;
	}
}

@include media-breakpoint-down(md) {
        .nome-empresa {
                margin-top: get($header-config, tablet-and-mobile, fixed, height);
                height: get($nome-empresa-config, tablet-and-mobile, default, height) !important;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-weight: bold;
                background-color: $gray-500 !important;
        }
}

.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-icon-button {
    outline: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-button {
	padding-right: 16px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-button {
	/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
	.mat-radio-label { margin-bottom: 4px; }
}

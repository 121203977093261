@use "sass:math" as math;

@mixin symbol-size($size) {
    > img {
        width: 100%;
        max-width: $size;
        height: $size;
    }

    .symbol-label {
        width: $size;
        height: $size;
    }

    $badge-size:  12px;

    .symbol-badge {
        width: $badge-size;
        height: $badge-size;
        top: -(math.div($badge-size, 2));
        right: -(math.div($badge-size, 2));

        &.symbol-badge-bottom {
            top: auto;
            bottom: -(math.div($badge-size, 2));
        }
    }

    &.symbol-circle {
        $distance: (math.div($size, 2)) * (1.41 - 1) - $badge-size;

        .symbol-badge {
            top: $distance;
            right: $distance;

            &.symbol-badge-bottom {
                top: auto;
                bottom: $distance
            }
        }
    }

    &.symbol-fixed {
        .symbol-label {
            width: $size;
            height: $size;
        }

        > img {
            width: $size;
            height: $size;
            max-width: auto;
        }
    }

        &.symbol-2by3 {
        .symbol-label {
            height: $size;
            width:  math.div($size * 3, 2);
        }

        > img {
            height: $size;
            width:  math.div($size * 3, 2);
            max-width: auto;
        }
    }
}


@include media-breakpoint-up(lg) {
	.content {
		padding: get($page-padding, desktop) 0;

				.footer-fixed & {
			padding-bottom: get($footer-config, fixed, height);
		}
	}
}

@include media-breakpoint-down(md) {
	.content {
		padding: get($page-padding, tablet-and-mobile) 0;

				.subheader-enabled.subheader-transparent & {
			padding-top: 0;
		}
	}
}

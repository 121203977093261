@use "sass:math" as math;

@mixin checkbox-solid-theme($bg-color, $checked-bg-color, $checked-tick-color) {
    > span {
        background-color: $bg-color;
        border: 1px solid transparent;
    }

    > input:focus ~ span {
        border: 1px solid transparent;
    }

    > input:checked ~ span {
        background-color: $checked-bg-color;

		&:after {
            border-color: $checked-tick-color;
        }
    }
}

@mixin checkbox-outline-theme($outline-color, $checked-outline-color, $checked-tick-color) {
	> span {
		background-color: transparent;
        border-color: $outline-color;
    }

	> input:focus ~ span {
		background-color: transparent;
        border-color: $outline-color;
    }

    > input:checked ~ span {
		background-color: transparent;
        border-color: $checked-outline-color;

		&:after {
            border-color: $checked-tick-color;
        }
    }
}

@mixin checkbox-size($base, $tick) {
	> span {
        height: $base;
        width: $base;

		&:after {
            width: math.div($tick, 2);
            height: $tick;
		}
	}
}

// form-checkbox-inputs
.custom-control-label:before{
    background:white;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
    background: #1E1E2D;
}

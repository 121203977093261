




$bullet-border-radius: 2rem;
$bullet-hor-width: 10px;
$bullet-hor-height: 2px;
$bullet-ver-width: 2px;
$bullet-ver-height: 10px;
$bullet-dot-size: 4px;
$bullet-dot-size: 4px;
$bullet-bar-width: 4px;


.bullet {
    
    display: inline-block;
    background-color: $gray-300;
    width: $bullet-hor-width;
    height: $bullet-hor-height;
    @include border-radius($bullet-border-radius);

    
    &.bullet-dot {
        width: $bullet-dot-size;
        height: $bullet-dot-size;
        border-radius: 100% !important;
    }

    
    &.bullet-ver {
        width: $bullet-ver-width;
        height: $bullet-ver-height;
    }

    
    &.bullet-bar {
        width: $bullet-bar-width;
        height: auto;
    }
}

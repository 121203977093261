.strikethrough {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;

    span {
        position: relative;
        display: inline-block;
		color: $text-muted;
		font-weight: 500;

		&:before,
	    &:after {
	        content: "";
	        position: absolute;
	        top: 50%;
	        width: 9999px;
	        height: 1px;
	        background: $gray-200;
	    }

	    &:before {
	        right: 100%;
	        margin-right: 15px;
	    }

	    &:after {
	        left: 100%;
	        margin-left: 15px;
	    }
    }
}
